import * as React from "react";
import { Button, CircularProgress } from "@mui/material";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { UsersAPI } from "../../api/services";
import Close from "@mui/icons-material/Close";
import DoneIcon from '@mui/icons-material/Done';
import { ToastContainer, toast } from 'react-toastify';
import AddUserModal from "./addUser";
import DownloadIcon from '@mui/icons-material/Download';
import { url } from '../../api/constants';

export default function UserScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [addModal, setAddModal] = React.useState(false);

  const getAllUsers = () => {
    UsersAPI.get_Users_All().then((res) => {
        setUsers(res.data);
        setIsLoading(false);
    })
  }

  React.useEffect(() => {
    getAllUsers();
  },[])

  const columns: any = [
    { field: "first_name", headerName: "Name", width: 300 },
    { field: "last_name", headerName: "Surname", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "provincial_address", headerName: "Province", width: 300 },
    {
      field: "is_admin", headerName: "Is Admin", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            {
              !params.row.is_admin ?
                (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                        let data = {
                            is_admin: !params.row.is_admin
                        }
                        UsersAPI.updateUser(data, params.row.id).then(() => {
                            getAllUsers();
                        })
                    }}
                  >
                    <Close style={{ fontSize: 23 }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                        let data = {
                            is_admin: !params.row.is_admin
                        }
                        UsersAPI.updateUser(data, params.row.id).then(() => {
                            getAllUsers();
                        })
                    }}
                  >
                    <DoneIcon style={{ fontSize: 23 }} />
                  </Button>
                )}
          </>);
      }
    },

  ];


  return (
    <>
      <div id="home-container">
        <Helmet>
          <title>{'AlleGrow-Users'}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
        <div className="header">
            <p style={{ fontSize: 25, fontWeight: 500, color: "#70a132", marginTop: 80  }}>Users</p></div>
            <div className="tableheader" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
  <Button
    variant="contained"
    sx={{
      color: "#70a132",
      backgroundColor: "#fff",
      padding: "5px 10px",
      fontSize: "14px",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }}
    onClick={() => setAddModal(true)}
  >
    Add
  </Button>
  <Button
    variant="contained"
    sx={{
      color: "#70a132",
      backgroundColor: "#fff",
      padding: "5px 10px",
      fontSize: "14px",
      height: "36px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    }}
     onClick={() => {
          window.open(url + "users/subscribed/");
        }}
  >
    <DownloadIcon sx={{ fontSize: "18px" }} />
    User Excel
  </Button>
</div>

          {isLoading ?
            (
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <CircularProgress size={50} style={{ marginTop: 50, color: '#70a132' }} />
              </div>
            ) : (<div style={{ width: "100%" }}>
              <DataGrid
                style={{ maxHeight: "100%", paddingLeft: 15, paddingRight: 15 }}
                rows={users}
                columns={columns}
                getRowId={(row: any) => row.id}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                rowSelection={false}
              />
            </div>)}
        </div>
      </div>
      {addModal && (
        <AddUserModal open={addModal} onClose={() => {
          setAddModal(false);
          getAllUsers();
        }} onSuccess={() => {
          toast.success("User Added", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        }}/>
      )}
      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </>
  );
}