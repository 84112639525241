import * as React from "react";
import { Button, CircularProgress, MenuItem, TextField } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import Close from "@mui/icons-material/Close";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./ingredient.scss";
import { ingredient } from "../../types/ingredient";
import { AllergyAPI, IngredientAPI, ProductAPI, SyncIngredients } from "../../api/services";
import AddIngredientModal from "./addIngredient";
import ExportIngredientModal from "./exportIngredient";
import EditModal from "./editIngredient";
import { ToastContainer, toast } from 'react-toastify';
import { url } from '../../api/constants';
import * as XLSX from "xlsx";
import DownloadIcon from '@mui/icons-material/Download';
interface Allergy {
  id: number;
  name: string;
 
}

export default function IngredientScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(false)
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);
  const [isExportModalOpen, setExportModalOpen] = React.useState(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [isOpenActivate, setIsOpenActivate] = React.useState(false);
  const [isOpenRemove, setIsOpenRemove] = React.useState(false);
  const [selectedIngredient, setSelectedIngredient] = React.useState<any>({})
  const [selectedIngredientProducts, setSelectedIngredientProducts] = React.useState<any>([])
  const [allergyContains, setAllergyContains] = React.useState<any>([])
  const [specialKey, setSpecialKey] = React.useState('')
  const [isDeleted, setIsDeleted] = React.useState({
    id: 0,
    is_deleted: false
  });
  const [isEditRow, setIsEditRow] = React.useState<ingredient>({
    id: 0,
    name: '',
    contains: '',
    allergy_name: '',
    associated_allergy: 0,
    is_deleted: false,
    created_by: 0,
    updated_by: 0,
    created_date: new Date,
    modified_date: new Date,
    allergies: []
  })
  // const [allergies, setAllergies] = React.useState([])
  // const [selectedAllergy, setSelectedAllergy] = React.useState(0)

  // const [IngredientData, setIngredientData] = React.useState<ingredient[]>([])
  const [allergies, setAllergies] = React.useState<Allergy[]>([]);
  const [selectedAllergy, setSelectedAllergy] = React.useState<number>(0);
  const [IngredientData, setIngredientData] = React.useState<ingredient[]>([]);




  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };



  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const getAllIngredients = () => {
      setIsLogged(true)
      IngredientAPI.getAllIngredients().then((res) => {
         console.log(res.data); 
        setIngredientData(res.data);
        setIsLoading(false)
      })
      AllergyAPI.getAllAllergies().then((res) => {
        setAllergies(res.data);
      })
  }
  React.useEffect(() => {
    getAllIngredients()

    AllergyAPI.getAllergyContains().then(async (response) => {
      
      setAllergyContains(response.data)
      // console.warn("--------------------list ", response.data)
    });

  }, [isAddModalOpen, isOpenDelete, isEditModalOpen,isOpenActivate])

  const deleteIngredient = (isDeleted: any) => {
    const deletdId = isDeleted.id;
    const data = { is_deleted: isDeleted.is_deleted }
    IngredientAPI.updateIngredient(data, deletdId).then((res) => {
      setIsOpenDelete(false)
      setIsOpenActivate(false)
    })
  }

  const removeProduct = () => {
    if (specialKey === 'All3Gr0w') {
      IngredientAPI.removeIngredient(selectedIngredient.id).then((res) => {
        toast.success("Ingredient Deleted", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOpenRemove(false);
        IngredientAPI.getAllIngredients().then((res) => {
          setIngredientData(res.data);
        })
      })
    } else {
      toast.error("Incorrect Key!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  const getProductsForIngredient = () => {
    if (Object.keys(selectedIngredient).length > 1) {
      ProductAPI.get_Ingredient_Products(selectedIngredient.id).then((res) => {
        setSelectedIngredientProducts(res.data);
      })
    }

  }

  React.useEffect(() => {
    getProductsForIngredient();
  }, [selectedIngredient])

  const columns: any = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params: any) => {
        const onViewClick = (e: any) => {
          setIsEditRow(params.row);
          setEditModalOpen(true);
        };

        return (
          <>
            <Button
              variant="contained"
              sx={{ m: 0, color: "#fff", backgroundColor: "#70a132", width: 30 }}
              onClick={onViewClick}
            >
              <ModeEditIcon style={{ fontSize: 23 }} />
            </Button>

          </>
        );
      },
    },
    { field: "name", headerName: "Name", width: 300 },

    { 
      field: "associated_allergy", 
      headerName: "Associated Allergy", 
      width: 300,
  },
  
    {
      field: "is_delete", headerName: "Is Active", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            {
              params.row.is_deleted ?
                (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: false });
                      if (!params.row.is_deleted) {
                        setIsOpenDelete(true);
                      } else {
                        setIsOpenActivate(true)
                      }
                    }}
                  >
                    <Close style={{ fontSize: 23 }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: true });
                      if (!params.row.is_deleted) {
                        setIsOpenDelete(true);
                      } else {
                        setIsOpenActivate(true)
                      }

                    }}
                  >
                    <DoneIcon style={{ fontSize: 23 }} />
                  </Button>
                )}
          </>);
      }
    },
    {
      field: "", headerName: "Delete", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              variant="contained"
              sx={{
                m: 1,
                color: "#fff",
                backgroundColor: "red",
                width: 30,
                "&:hover": {
                  backgroundColor: "red !important", // Set the background color on hover
                },
              }}
              onClick={() => {
                setSelectedIngredient(params.row)
                setIsOpenRemove(true)
              }}
            >
              <DeleteIcon style={{ fontSize: 23 }} />
            </Button>
          </>
        );
      }
    },
  ];

  const [addModalOpenUpload, setAddModalOpenUpload] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
   const [allIngredients, setAllIngredients] = React.useState<any[]>([]);
    const [ingredients, setIngredients] = React.useState<any>([]);
  function handleFileChange(event: any) {
    setSelectedFile(event.target.files[0]);
  }
  const syncIngredients = async (ingredients: any[]) => {
    try {
      const formattedIngredientData = ingredients
        .slice(0)
        .map((row: any, index) => ({
          name: row.Name,
          contains: row.Contains,
        }));
  
      console.log('Formatted ingredient data:', formattedIngredientData);
  
      const result = await SyncIngredients.syncIng(formattedIngredientData);
  
      console.log('Sync result:', result); // Log the successful response
  
      setAllIngredients(result.data);
      return result.data;
    } catch (error) {
      console.error('Error syncing ingredients:', error); // Log any error
      throw error; // Propagate the error
    }
  };
  
  
  const fetchIngredientPreviewData = async () => {
    if (!selectedFile) return;
  
    setIsLoading(true);
    setIngredients([]); // Assume a state `ingredients` exists for ingredient data
    setAddModalOpenUpload(false);
  
    console.log('Reading file...'); // Log before file reading starts
  
    await new Promise((resolve) => setTimeout(resolve, 3000));
  
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContent = event.target?.result; // Use optional chaining to access result
      if (fileContent) {
        const workbook = XLSX.read(fileContent, { type: 'binary' });
        const ingredientSheetName = workbook.SheetNames[0]; // Assuming the first sheet contains ingredient data
        const ingredientSheet = workbook.Sheets[ingredientSheetName];
        const parsedIngredientData: any[] = XLSX.utils.sheet_to_json(ingredientSheet);
  
        console.log('Parsed ingredient data:', parsedIngredientData); // Log parsed data
  
        const tempIngredients = await syncIngredients(parsedIngredientData);
  
        const formattedIngredientData = parsedIngredientData.map((row: any) => ({
          name: row.Name,
          mayContain: row.Contains || '',
        }));
  
        setIngredients(formattedIngredientData);
        setIsLoading(false);
      }
    };
    reader.readAsBinaryString(selectedFile);
  };

  const openExportModal = () => {
    setExportModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };
  
 

  
  return (
    <>
      {isLogged ? (<div id="home-container">
        <Helmet>
          <title>{'AlleGrow-Ingredient'}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
          <div className="header">
            <p style={{ fontSize: 25, fontWeight: 500, color: "#70a132", marginTop: 80 }}>Ingredients</p></div>
            <div className="tableheader" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
  <Button
    variant="contained"
    sx={{
      color: "#70a132",
      backgroundColor: "#fff",
      width: 25,
      fontSize: 15,
      height: 30,
    }}
    onClick={openAddModal}
  >
    Add
  </Button>
  
  <Button
    variant="contained"
    sx={{
      color: "#70a132",
      backgroundColor: "#fff",
      width: "125px",
      fontSize: 15,
      height: 30,
    }}
    onClick={() => {
      window.open(url + "product/upload/");
    }}
  >
    <DownloadIcon sx={{ marginRight: 0.5 }} />
    Template
  </Button>
  
  <Button
    variant="contained"
    sx={{
      color: "#70a132",
      backgroundColor: "#fff",
      width: 100,
      fontSize: 15,
      height: 30,
    }}
    onClick={() => setAddModalOpenUpload(true)}
  >
    Add File
  </Button>
  
  <Button
  variant="contained"
  sx={{
    color: "#70a132",
    backgroundColor: "#fff",
    width: 200, // Increased width
    fontSize: 15,
    height: 30,
  }}
  onClick={openExportModal}
>
  Export Ingredients
</Button>


</div>

          {isLoading ?
            (
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <CircularProgress size={50} style={{ marginTop: 50, color: '#70a132' }} />
              </div>
            ) : (
              <div style={{ width: "100%" }}>
              <Select
                value={selectedAllergy}
                onChange={(e) => setSelectedAllergy(e.target.value as number)}
                displayEmpty={true}
                inputProps={{ 'aria-label': 'Select an option' }}
                className='drop-down'
              >
                <MenuItem value={0}>Select an allergy</MenuItem>
                {allergies.map((option: Allergy) => (
                  <MenuItem key={option.name} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <DataGrid
                style={{ maxHeight: "100%", paddingLeft: 15, paddingRight: 15 }}
                rows={selectedAllergy !== 0 
                  ? IngredientData.filter((item: ingredient) => {
                      const selectedAllergyName = allergies.find(
                        (allergy: Allergy) => allergy.id === selectedAllergy
                      )?.name;
                      
                      return selectedAllergyName ? item.allergies.includes(selectedAllergyName) : false;
                    })
                  : IngredientData
                }
                columns={columns}
                getRowId={(row) => row.id}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                rowSelection={false}
              />
            </div>
            )}
        </div>
        <Dialog
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deactivate Ingredient"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to deactivate this ingredient?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteIngredient(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenDelete(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenActivate}
          onClose={() => setIsOpenActivate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Activate Ingredient"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to  activate this ingredient?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteIngredient(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenActivate(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenRemove}
          onClose={() => setIsOpenRemove(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Ingredient"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please enter the special key to remove the ingredient:
            </DialogContentText>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="name"
              name="name"
              InputProps={{
                disableUnderline: true, // <== added this
              }}
              placeholder="Key"
              className='addText'
              onChange={(e) => setSpecialKey(e.currentTarget.value)}
            />
            <DialogContentText id="alert-dialog-description" style={{ fontWeight: 'bold' }}>
              The following products contain this ingredient:
            </DialogContentText>
            {selectedIngredientProducts.map((x: any, idx: any) => {
              return (
                <DialogContentText id="alert-dialog-description">
                  <ul>
                    <li>
                      {x.name}
                    </li>
                  </ul>

                </DialogContentText>
              )
            })}
          </DialogContent>
          <DialogActions>

            <Button onClick={() => setIsOpenRemove(false)} autoFocus>
              Cancel
            </Button>
            <Button variant="contained" sx={{
              m: 1,
              color: "#fff !important",
              backgroundColor: "red",
              width: 30,
              "&:hover": {
                backgroundColor: "red !important", color: '#fff !important'// Set the background color on hover
              },
            }} onClick={removeProduct}><DeleteIcon /></Button>
          </DialogActions>
        </Dialog>
        <AddIngredientModal open={isAddModalOpen} onClose={closeAddModal} />
        <ExportIngredientModal open={isExportModalOpen} onClose={closeExportModal} />
        <EditModal open={isEditModalOpen} onClose={closeEditModal} ingredientRow={isEditRow} options={allergyContains} getAllIngredients={getAllIngredients} />
      </div>) : (
        <CircularProgress />
      )}
       <Dialog
          open={addModalOpenUpload}
          onClose={() => setAddModalOpenUpload(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add Excel Data File"}
          </DialogTitle>
          <DialogContent>
            <input type="file" onChange={(e) => handleFileChange(e)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddModalOpenUpload(false)} autoFocus>
              Cancel
            </Button>
            <Button onClick={fetchIngredientPreviewData} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}