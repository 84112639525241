import React, { Fragment } from "react";
import "./about.scss";
import logo from "../../assets/logo/FoodAllergySupportApp.png";
import Navbar from "./navbar";
import Footer from "./footer";
import { ProductAPI } from "../../api/services";
import { Box } from "@mui/material";


interface Link {
  url: string;
  title: string;
}

const Disclaimer = () => {
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/explore", title: "Explore Allegrow and Where To Start" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
    { url: "/privacy", title: "Privacy" },
  ];

  const [totalProducts, setTotalProducts] = React.useState(0);
  

  React.useEffect(() => {
    ProductAPI.getProductCount().then((res) => {
      setTotalProducts(res.data);
    });
  }, []);


  const handleScrollToSection = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  


  return (
    <>
      <Navbar navbarLinks={lnks} />
      <Box
        sx={{
          pt: "0px",
          height: "calc(100vh - 150px)",
          marginBottom: 10,
          flex: 1,
          overflowY: "auto", // Allow vertical scrolling
          scrollbarWidth: "none", // For Firefox
          msOverflowStyle: "none", // For Internet Explorer and Edge
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Opera
          },
        }}
        className="aboutContainer"
      >
        <Box
          className="top"
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            pt: 5,
          }}
        >
          <Box
            className="top-left"
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                fontFamily: "margarosa",
                marginBottom: 0,
                fontSize: "2em",
              }}
            >
              Disclaimer
            </h2>
          </Box>
          <Box
            className="top-right"
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="homeImage" src={logo} width={'400px'} height={'300px'} alt="Image" />
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "justify",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            MOBILE APPLICATION DISCLAIMER & TERMS OF USE FOR ALLEGROW FOOD
            ALLERGY SUPPORT (PTY) LTD. <br />
            <br />
            INTRODUCTION:
          </p>

          <p style={{ width: "80%", fontSize: "20px" }}>
            This mobile application (“the App”) is owned and operated by
            Allegrow Food Allergy Support (Pty) Ltd (2020/598421/07)
            <br />
            <br />
            Your access and use of The App constitutes your acceptance of and
            agreement to, the following disclaimer and terms of use as well as
            supplemental terms. We reserve the right to modify, alter, amend,
            update or change provisions herein without notice. Your continued
            usage of the App constitutes agreement with the changes, where
            applicable. We advise you to review this page periodically for any
            changes. PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE
            SERVICES and If you do not agree with or do not accept any part of
            this agreement, you must not use the App.
          </p>

          <p style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            INDEX:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
          <a href="#" onClick={() => handleScrollToSection("section1")}>1. INFORMATION PURPOSES ONLY</a><br />
           
            <a href="#" onClick={() => handleScrollToSection("section2")}> 2. INFORMATION IS NOT DEEMED COMPLETE</a><br />
            <a href="#" onClick={() => handleScrollToSection("section3")}>3. NON-LIABILITY OF RETAIL SUPPLIERS</a><br />
           
           <a href="#" onClick={() => handleScrollToSection("section4")}>4. MEDICAL EMERGENCY</a><br />
           <a href="#" onClick={() => handleScrollToSection("section5")}>5. NO REPRESENTATION OR WARRANTY</a><br />
           
           <a href="#" onClick={() => handleScrollToSection("section6")}>  6. NO ENDORSEMENT</a><br />
           <a href="#" onClick={() => handleScrollToSection("section7")}>7. SELF MANAGEMENT OF HEALTH PROBLEMS</a><br />
          
          <a href="#" onClick={() => handleScrollToSection("section8")}> 8. DO NOT DISREGARD MEDICAL ADVICE</a><br />
          <a href="#" onClick={() => handleScrollToSection("section9")}>9. CONSULTATION WITH PHYSICIAN OR HEALTHCARE PROVIDER</a><br />
           
           <a href="#" onClick={() => handleScrollToSection("section10")}>   10. LINKS ARE AT OWN RISK</a><br />
           <a href="#" onClick={() => handleScrollToSection("section11")}>11. MOBILE APPLICATION FEATURES</a><br />
          
          <a href="#" onClick={() => handleScrollToSection("section12")}>12. LIABILITY</a><br />
          <a href="#" onClick={() => handleScrollToSection("section13")}>  13. LIMITS UPON EXCLUSIONS OF LIABILITY</a><br />
          
          <a href="#" onClick={() => handleScrollToSection("section14")}>  14. PROTECTION OF PERSONAL INFORMATION</a><br />
         
            - Usage
            <br />
            - Sources
            <br />
            - Sharing
            <br />
            - User Rights
            <br />
            - Storage
            <br />
            - General conditions
            <br />
          <a href="#" onClick={() => handleScrollToSection("section15")}> 15. SUPPLEMENTAL TERMS</a><br />
         <a href="#" onClick={() => handleScrollToSection("section16")}> 16. INTELLECTUAL PROPERTY RIGHTS</a><br />
         <a href="#" onClick={() => handleScrollToSection("section17")}>17. FORCE MAJEURE</a><br />
            <a href="#" onClick={() => handleScrollToSection("section18")}> 18. BREACH</a><br />
            <a href="#" onClick={() => handleScrollToSection("section19")}> 19. DISPUTE RESOLUTION</a><br />
           
           <a href="#" onClick={() => handleScrollToSection("section20")}>20. GOVERNING LAW AND JURISDICTION</a><br />
           <a href="#" onClick={() => handleScrollToSection("section21")}>21. ENTIRE AGREEMENT</a><br />
           
           <a href="#" onClick={() => handleScrollToSection("section22")}>  22. NON-VARIATION</a><br />
           <a href="#" onClick={() => handleScrollToSection("section23")}>23. SEVERABILITY</a><br />
          
          <a href="#" onClick={() => handleScrollToSection("section24")}>24. ANNEXURE “A”</a><br />
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>DEFINITIONS:</p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            <strong>“The App”</strong> means, collectively and individually, the
            Allegrow Food Allergy Support mobile application.
            <br />
            <br />
            <strong>“App User”</strong> means an end user who makes use of the
            features and functionalities of the App.
            <br />
            <br />
            <strong>“Content”</strong> means all of our App’s features and
            technical resources available to Users, including, but not limited
            to, data, text, scripts, technology, know-how, designs, logos,
            graphics, images, illustrations, or any other content contained
            within the App, and any interactive features generated, provided, or
            otherwise made accessible through our App.
            <br />
            <br />
            <strong>“Intellectual Property Rights”</strong> mean any and all
            registered and unregistered rights granted, applied for, or
            otherwise now or hereafter in existence under or related to any
            patent, copyright, trademark, trade secret, database protection, or
            another intellectual property right, and all similar or equivalent
            rights or forms of protection, in any part of the world.
            <br />
            <br />
            <strong>“User Account”</strong> means an account associated with our
            App, the purpose of which is to allow the User to access and use our
            App or certain parts of them and to create User Content.
            <br />
            <br />
            <strong>“User Content”</strong> means any content provided by a User
            including any entered, recorded, stored, used, controlled, modified,
            disclosed, transmitted, or erased information and data.
            <br />
            <br />
            <strong>“User Data”</strong> means data in electronic form inputted
            or collected through the App by or from any User (in the broadest
            possible interpretation of the term), including without limitation
            personal data (as described in the POPI Act).
            <br />
            <br />
            <strong>“Agreement”</strong> means this agreement including any
            amendments, if applicable.
            <br />
            <br />
            <strong>“Supplemental Terms”</strong> means additional terms,
            conditions, guidelines, policies, or rules, as required by law.
          </p>
          <p id="section1" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            1. INFORMATION PURPOSES ONLY:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            Your personal health data is unique and as such you may have
            particular conditions which react opposite to what is generally
            medically known and recommended in the App. We strongly advise that
            the App be used in conjunction with advice from a healthcare
            practitioner after identification of these potential conditions.{" "}
            <br />
            <br />
            1.1 All information, content, and material displayed on the App by
            Allegrow Food Allergy Support (Pty) Ltd. is for informational
            purposes only and are not intended to serve as a substitute for the
            consultation, diagnosis, and/or medical treatment of a qualified
            physician or healthcare provider. <br />
            <br />
            1.2 Reliance on any information displayed on the App and provided by
            Allegrow Food Allergy Support (Pty) Ltd, their employees, contracted
            writers, or medical professionals presenting content for publication
            to Allegrow Food Allergy Support (Pty) Ltd, is solely at your own
            risk.
            <br />
            <br />
            1.3. All information on the App including, but not limited to,
            allergies and accompanying symptoms, ingredients and recommended
            products are approved by a Registered Dietitian before publication.
            However, nothing presented on the App creates a professional-patient
            relationship between you and said Registered Dietitian or staff
            employed by Allegrow Food Allergy Support (Pty) Ltd. 1.4 While the
            Registered Dietitian draws on prior professional expertise and
            background in the dietetics area, you acknowledge that the
            information provided are only supportive in nature and for
            educational purposes and not to cure, prevent, diagnose, or treat
            any allergy-related symptom or health condition.
          </p>
          <p id="section2" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            2. INFORMATION IS NOT DEEMED COMPLETE:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            The information contained on the Allegrow Food Allergy Support (Pty)
            Ltd. mobile application is compiled from a variety of sources and is
            not considered complete. The information accessed through this
            mobile application is provided on an “as is” and “as available”
            basis without any warranties, whether expressed or implied. <br />
            <br />
            Please note that, information pertaining products and product
            ingredients may change periodically and the dates of these changes
            are not always communicated by the manufacturers on a timely basis.
            The App uses open source databases, so it could mean that ingredient
            and nutrition facts are out of date. Even though there is a
            potential (non-)presence of an ingredient noted in the App,
            manufacturers change ingredients all the time. Information is
            updated proactively on a 6 (six) month cycle. <br />
            <br />
            This means, if you are allergic to specific foods, you should not
            rely on this app. Always check the food label and be conscious of
            mislabelling. Moreover, this App does not give you advice on what to
            eat and what not to eat, please consult your doctor or registered
            dietician for advise related to your specific requirements. 
          </p>
          <p id="section3" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            3. NON-LIABILITY OF SUPPLIERS AND THIRD PARTIES:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            In addition to paragraph 2 above, products listed on our App include
            those provided by third parties such as retail stores. As these
            providers are not manufacturers themselves, they are not liable for
            any misinformation provided to Allegrow Food Allergy Support (Pty)
            Ltd or subsequent damages which may arise as a result of the
            misinformation or ingredients listed incorrectly. Specific
            declaimers are listed on Annexure “A”.
          </p>
          <p id="section4" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            4. MEDICAL EMERGENCY:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            If you have, or think you have, a medical emergency, call your
            nearest medical emergency facility, doctor or ambulance service
            immediately.
          </p>
          <p id="section5" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            5. NO REPRESENTATION OR WARRANTY:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            5.1 The App is continually under development and any changes in
            medical research may impact the health, product and nutritional
            content that appears on the App. No assurance can therefore be given
            that the advice and or products displayed on the App will include
            the most recent findings or developments with respect to a
            particular product at the time when the App is utilised.
            <br />
            <br />
            Allegrow Food Allergy Support (Pty) Ltd makes no warranty or
            representation of any kind, implied or express, as to:
            <br />
            <br />
            5.1.1 its reliability, timelines, accuracy, completeness,
            usefulness, adequacy or suitability or appropriateness for any
            purpose.
            <br />
            <br />
            5.1.2 the validity of information contained or displayed, whether
            authored by Allegrow Food Allergy Support (Pty) Ltd or others.
            <br />
            <br />
            5.1.3 any form of human or machine error, omissions, delays,
            warranty of merchantability, interruptions or losses, including the
            loss of any data.
            <br />
            <br />
            5.2 Without limiting the scope of Section 12, we do not warrant or
            represent that the information on this mobile application:
            <br />
            <br />
            5.2.1 will be constantly available, or available at all; or
            <br />
            <br />
            5.2.2 is true, accurate, complete, current or non-misleading.
            <br />
            <br />
            5.3 As the App user, you:
            <br />
            <br />
            5.3.1 represent and warrant that:
            <br />
            <br />
            5.3.1 You have the full legal authority to enter into this
            Agreement;
            <br />
            <br />
            5.3.2 You have read and understand all parts of this Agreement;
            <br />
            <br />
            5.3.3 You are at least 18 (eighteen) years old or have the oversight
            of a legal guardian or parent who are able to assist with the
            completion of your profile and furnishing of any medical information
            you enter.
          </p>
          <p id="section6" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            6. NO ENDORSEMENT:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            6.1 The content of the Allegrow Food Allergy Support (Pty) Ltd.
            mobile application is not intended to endorse or recommend any
            particular type of medical treatment. Neither is the nutrition
            information intended to be understood as putting forth any cure for
            any type of acute or chronic health problem. Should any reader have
            any health care related questions, promptly call or consult your
            physician or healthcare provider. <br />
            <br />
            6.2 The Allegrow Food Allergy Support (Pty) Ltd. mobile application
            is not intended to endorse or recommend any specific food product
            brand, but to inform the user of the food options or products that
            are deemed suitable and safe for consumption as per a specific food
            allergy and/or intolerance.
          </p>
          <p id="section7" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            7. SELF MANAGEMENT OF HEALTH PROBLEMS:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            The information contained on this mobile application is not intended
            to recommend the self-management of health conditions or wellness.
            By acceptance of this agreement, you understand that the App is not
            customized for any individual and is presented without any type of
            health assessment or knowledge of any individual health conditions
            which you may have. As results may vary from person to person, you
            undertake to always consult a qualified healthcare professional
            before changing your dietary intake. We are here to provide an
            ingredient tool, but we can’t take the place of your own label
            scanning and research. 
          </p>

          <p id="section8" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            8. DO NOT DISREGARD MEDICAL ADVICE:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            8.1 No information contained on this mobile application should be
            used by any reader to disregard medical and/or health related advice
            or provide a basis to delay consultation, or discontinue
            consultation, with a physician or a qualified healthcare provider.{" "}
            <br />
            <br />
            8.2 Never disregard professional medical advice, delay in seeking
            it, or discontinue medical treatment, because of something you have
            read on this mobile application.
          </p>
          <p id="section9" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            9. CONSULTATION WITH PHYSICIAN OR HEALTHCARE PROVIDER:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            You should not use any information contained on this mobile
            application to initiate use of dietary supplements, vitamins, herbal
            and nutritional products or homeopathic medicine, and other
            described products prior to consulting first with a physician or
            healthcare provider.
          </p>

          <p id="section10" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            10. LINKS ARE AT OWN RISK:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            Links to educational content not created by the Allegrow Food
            Allergy Support (Pty) Ltd. mobile application are taken at your own
            risk. Allegrow food allergy support (Pty) Ltd is not responsible for
            the claims of external websites, external mobile applications and /
            or education companies. Questions or comments regarding the App,
            including any reports of non-functioning links, should be submitted
            to support@allegrow.co.za
          </p>
          <p id="section11" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            11. MOBILE APPLICATION FEATURES:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            11.1 To the extent permitted under applicable law, we reserve the
            right at any time and from time-to-time to modify, suspend, upgrade,
            change, or discontinue, temporarily or permanently, the App (or any
            part of it) with or without notice or liability to you.
            <br />
            <br />
            We will use commercially reasonable efforts to make the App
            available, except:
            <br />
            <br />
            (a) during planned downtime (of which we will give reasonable
            advance notice if possible); <br />
            <br />
            (b) for any unavailability caused by circumstances beyond our
            reasonable control, including, for example, a force majeure event,
            performance issues with individual features, external network or
            equipment problems, or issues that are related to external apps or
            third parties; or <br />
            <br />
            (c) as necessary to update the App to ensure the security and
            integrity of the App. <br />
            <br />
            11.2 The mobile application includes interactive features that allow
            users to communicate with us. You acknowledge that, because of the
            limited nature of communication through our application’s
            interactive features, any assistance you may receive using any such
            features is likely to be incomplete and may even be misleading. We
            assume no responsibility for errors or omissions that may appear on
            the App.
            <br />
            <br />
            11.3 Any assistance you may receive using any of our mobile
            application’s interactive features does not constitute specific
            advice and accordingly should not be relied upon without further
            independent confirmation.
            <br />
            <br />
            11.4 You agree that you will not upload any material that contains
            software viruses or any other computer code, files or programs
            designed to interrupt, destroy or limit the functionality of the
            App.
          </p>

          <p id="section12" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            12. LIABILITY:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            12.1 Subject to clause 12, Allegrow Food Allergy Support (Pty) Ltd
            are not liable for any damages or injury resulting from your access
            to, or inability to access, this site and/or its products, or from
            your reliance upon any information provided in this site or
            products. <br />
            <br />
            12.2 Allegrow Food Allergy Support (Pty) Ltd are not liable for any
            damages or injury resulting from incorrect information supplied by
            you or an omission of symptoms which may affect the outcome of
            advice or recommendations generated on the app. You must provide
            accurate account information and promptly update this information if
            it changes. <br />
            <br />
            12.3 By acceptance of this agreement, you understand that there are
            risks associated with making changes to your diet or nutrition
            regimen. In the event that you make changes based on the information
            on the App, you hereby assume all risks, known and unknown, inherent
            to nutrition related outcomes, physical changes and/or injuries
            which may result from the use of these services. <br />
            <br />
            12.4 You agree to defend, indemnify, and hold Allegrow Food Allergy
            Support (Pty), its officers, directors, employees, agents,
            licensors, and suppliers, harmless from and against any claims,
            actions or demands, liabilities and settlements including without
            limitation, reasonable legal and accounting fees, resulting from, or
            alleged to result from: <br />
            <br />
            (a) your use of the App, <br />
            <br />
            (b) any User Content you upload, <br />
            <br />
            (c) your use of or reliance on any Content, or <br />
            <br />
            (d) your violation of these Terms and Conditions.
          </p>
          <p id="section13" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            13. LIMITS UPON EXCLUSIONS OF LIABILITY:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            Nothing in this disclaimer and terms of use will: <br />
            <br />
            13.1 limit or exclude any liability for death or personal injury
            resulting from -gross-negligence;
            <br />
            <br />
            13.2 limit or exclude any liability for fraud or fraudulent
            misrepresentation;
            <br />
            <br />
            13.3 limit any liabilities in any way that is not permitted under
            applicable law; or
            <br />
            <br />
            13.4 exclude any liabilities that may not be excluded under
            applicable law.
          </p>
          <p id="section14" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            14. PROTECTION OF PERSONAL INFORMATION:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            By utilising this App, you hereby acknowledge that you have read and
            accepted the following Protection of Personal Information (POPI)
            disclaimer and agree that Allegrow Food Allergy Support (Pty) Ltd
            may have access to and use personal information which you provide
            during the registration process and while using the App. <br />
            <br />
            As per the Protection of Personal Information Act, Act 4 of 2013
            (“POPI”), personal information refers to information that identifies
            or relates specifically to you as a person or data subject, for
            example, your name, age, gender, identity number and your email
            address. <br />
            <br />
            <strong>
              14.1 The Personal Information collected from an App user is used:{" "}
            </strong>
            <br />
            <br />
            14.1.1 To compile a profile of the user on the App and webserver
            <br />
            <br />
            14.1.2 To authenticate the user upon login
            <br />
            <br />
            14.1.3 To provide the App user with information which Allegrow Food
            Allergy Support (Pty) Ltd believes may be of interest to the App
            user, such as new products and information.
            <br />
            <br />
            14.1.4 To enable the uploading and submission of new product details
            found by the App user to Allegrow Food Allergy Support (Pty) Ltd.
            <br />
            <br />
            14.1.5 To communicate with the App user via interactive features in
            the App. <br />
            <br />
            <strong>
              14.2 Sources where Personal Information may be obtained include:
            </strong>
            <br />
            <br />
            14.2.1 Directly from the App user during the registration process
            and or the completion of various data-input fields on the App
            <br />
            <br />
            14.2.2 From public registers, including but not limited to the
            Health Practitioners Council of South Africa when verifying
            credentials, if applicable.
            <br />
            <br />
            14.2.3 From people or entities legally entitled to provide Allegrow
            Food Allergy Support (Pty) Ltd with such personal information.
            <br />
            <br />
            14.2.4 Log data and cookies, if applicable. <br />
            <br />
            <strong>14.3 Sharing of Personal Information:</strong>
            <br />
            <br />
            14.3.1 Any information entered on the App will be treated as
            confidential and Allegrow Food Allergy Support (Pty) Ltd. will not
            disclose information to an external party unless legally required to
            do so. <br />
            <br />
            14.3.2 No information will be transferred to a Third Party without
            the explicit consent of the App user, unless legally required to do
            so.
            <br />
            <br />
            14.3.3 The App user grants Allegrow Food Allergy Support (Pty) Ltd.
            permission to share and relay personal information internally for
            the purpose of the App working correctly.
            <br />
            <br />
            <strong>
              {" "}
              14.4 Your Rights regarding your Personal Information:
            </strong>
            <br />
            <br />
            14.4.1 Right of access to and the right to rectify or update the
            personal information collected.
            <br />
            <br />
            14.4.2 The right to object at any time to the processing of the
            personal information in which event the consequences of the
            objection will be explained to the App user
            <br />
            <br />
            14.4.3 The right to request Allegrow Food Allergy Support (Pty) Ltd.
            to no longer process the personal information of the App user and
            remove it from the webserver and database. <br />
            <br />
            <strong> 14.5 Personal Information is stored as follows:</strong>
            <br />
            <br />
            14.5.1 Select information necessary to create an account for the App
            user is saved on the webserver.
            <br />
            <br />
            14.5.2 The information is encrypted and protected to the best of the
            ability of Allegrow Food Allergy Support (Pty) Ltd but cannot
            guarantee against unauthorized access via hacking by a third party.
            <br />
            <br />
            14.5.3 Personal details including but not limited to the food
            allergies and or intolerances of the app user and identifying
            medical records are saved locally on their device. This information
            and or medical records are not accessible by Allegrow Food Allergy
            Support (Pty) Ltd. <br />
            <br />
            14.5.4 It remains the responsibility of the user prevent
            unauthorised access to their device. <br />
            <br />
            <strong>
              {" "}
              14.6 General Conditions pertaining to Personal Information:
            </strong>
            <br />
            <br />
            14.6.1 Allegrow Food Allergy Support (Pty) Ltd. accepts no liability
            whatsoever for any loss, damage (whether direct, indirect, special
            or consequential) and/or expenses of any nature whatsoever which may
            arise as a result of, or which may be attributable directly or
            indirectly from information made available on this App or links, or
            actions or transaction resulting there from.
            <br />
            <br />
            14.6.2 The Laws of the Republic of South Africa will govern all
            terms and conditions of any products and services contained in the
            App You hereby consent and submit to the jurisdiction of the South
            African courts in regard to all proceedings, actions, applications
            or the like instituted by each party against the other, and in any
            way arising from any stated terms and conditions. <br />
            <br />
            14.6.3 If you do not agree to be bound by these terms, please exit
            this page and do not access or use the App.
            <br />
            <br />
          </p>

          <p id="section15" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            15. SUPPLEMENTAL TERMS:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            15.1 From time to time, new legislative requirements may require
            additional terms, conditions, guidelines, policies, or rules
            (“Supplemental Terms”) to be added to this Agreement. These terms
            are implemented for your protection and benefit and will be
            implemented only as per legislative provisions. <br />
            <br />
            15.2 Any Supplemental Terms become part of your agreement with us if
            you use the App and if there is a conflict between this Agreement
            and the Supplemental Terms, the Supplemental Terms will control to
            the extent of the conflict with respect to your access to and use of
            the App. <br />
            <br />
            15.3 Supplemental Terms includes but is not limited to: <br />
            <br />
            15.3.1 Privacy Policies <br />
            <br />
            15.3.2 Consumer Protection laws and provisions. <br />
            <br />
            15.3.3 The Electronic Communications and Transactions Act 25 of 2002
            <br />
            <br />
          </p>
          <p id="section16" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            16. INTELLECTUAL PROPERTY RIGHTS:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            16.1 Allegrow owns the Intellectual Property Rights pertaining to
            their specific concept of the food allergy based app including the
            name and content as well as the layout and iconography which is
            unique to Allegrow.
            <br />
            <br />
            16.2 Any use of the Services other than as specifically authorized
            herein, without our prior written permission, is strictly
            prohibited, will terminate the license granted herein, and will
            violate our Intellectual Property Rights.
            <br />
            <br />
            16.3 Should the App and by extension, this agreement and accepted
            terms of use, be terminated for any reason including but not limited
            to removal of the App by the App stores or the ceasing of business
            by Allegrow, the user of the App who is familiar with the aspects of
            14.1 above, may not develop or use any of the aspects or elements of
            the app for a period of 24 (twenty four) months from date of
            termination. <br />
            <br />
            16.4 If Allegrow becomes aware of any infringement or illegal use by
            the user of any of its Intellectual Property Rights, it will
            promptly notify the user of such infringement or illegal use.
            <br />
            <br />
            16.5 It will be within the discretion of Allegrow to determine what
            steps, if any, will be taken against the user to bring any
            infringement or illegal use to an end, alternatively the payment of
            royalties by the user to Allegrow, for the duration of the 24
            (twenty four) months.
            <br />
            <br />
            16.6 In the event that a user of the app shares the information with
            a third party who is not a user of the app, the app user will be
            regarded as the infringer and all clauses in paragraph 14 will
            remain applicable to him/her/it. <br />
            <br />
          </p>

          <p id="section17" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            17. FORCE MAJEURE:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            17.1 Allegrow will not be liable for delays or failure of
            performance under this Agreement resulting from Force Majeure
            including but not limited to acts of God (vis major), failure of
            affiliates to perform, failure of the manufactures to inform of
            changes in the ingredients used, power failures, failure to update
            the app accordingly due to technical difficulties or other causes
            beyond the control of Allegrow which renders performance impossible.
            <br />
            <br />
            17.2 In the event of force majeure, this terms of use agreement with
            the user of the App will be suspended for the entire period during
            which the force majeure occurrence continues. Should the occurrence
            still continue after 60 (Sixty) days, Allegrow will have lex
            commissoria and may cancel the agreement without further notice to
            the App user. <br />
            <br />
          </p>
          <p id="section18" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            18. BREACH:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            Allegrow may terminate this Agreement and the User’s access to the
            App with immediate effect if it becomes known that the User
            committed a breach of any of the provisions of this Agreement
            including but not limited to the use or duplication of contents of
            the App in a manner for which the app was not intended and said
            usage leads to damages.
          </p>

          <p id="section19" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            19. DISPUTE RESOLUTION:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            19.1 Parties will at all times act in good faith towards each other
            and resolve all disputes informally. Should a dispute arise; the
            aggrieved party will notify the other party in writing and within 7
            (seven) days of the dispute arising. The Parties will then attempt
            to resolve the disputes amicably via negotiation within 10 (ten)
            days from the date the notice was provided.
            <br />
            <br />
            19.2 Should this fail; the Parties may make use of arbitration in
            accordance with the rules and procedures of The Arbitration
            Foundation of South Africa (AFSA).
            <br />
            <br />
            19.3 The Arbitration will be held in the English language in
            Johannesburg, Gauteng <br />
            <br />
            19.4 The Arbitrator’s decision is final and the proceedings may be
            subject to review by a South African court of competent
            jurisdiction.
            <br />
            <br />
            19.5 In the event of any disputes or legal action taken on this
            Agreement, the successful party shall be entitled to costs on a
            scale of attorney-and-own-client scale. <br />
            <br />
            19.6 This clause is severable from the rest of the agreement and
            will remain in effect should the rest of this agreement be cancelled
            for any reason, extending to each Party’s successors in title,
            assignees, trustees, executives and liquidators.
            <br />
            <br />
          </p>
          <p id="section20" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            20. GOVERNING LAW AND JURISDICTION:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            This Agreement is to be governed, interpreted and implemented in
            accordance with the laws of the Republic of South Africa. The
            Parties consent to the jurisdiction of the magistrate’s courts in
            terms of section 45 of the Magistrate’s Courts Act 32 of 1944 in
            respect of any arbitration proceedings being taken on review.
          </p>

          <p id="section21" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            21. ENTIRE AGREEMENT:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            This Agreement reflects the entire agreement between the parties and
            supersedes and novates in its entirety any previous understandings
            or agreements between the parties. Both parties acknowledge that
            they do not enter the agreement in reliance on any representation,
            warranty or other provision except as expressly provided in this
            agreement, and any conditions, warranties, or other terms implied by
            statute or common law are excluded from this agreement. Neither
            party will have any right or remedy arising from any undertaking,
            warranty or representation that is not included in this document.
          </p>
          <p id="section22" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            22. NON-VARIATION:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            No variation of this Agreement, will be of any force or effect
            unless updated on the App by Allegrow. Except where the context
            provides otherwise, the remaining unaltered provisions of the Main
            agreement shall, mutatis mutandis, continue to apply after
            amendments to specified clauses are updated on the App.
          </p>

          <p id="section23" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            23. SEVERABILITY:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            Any provision in this Agreement which is or may become illegal,
            invalid or unenforceable in any jurisdiction affected by this
            Agreement will be severed from this Agreement, without invalidating
            the remaining provisions of this Agreement or affecting the validity
            or enforceability of such provision.
          </p>
          <p id="section24" style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            24. ANNEXURE “A”:
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            <strong> a). Pick n Pay: </strong>
            <br />“ Although we strive to keep this information as accurate as
            possible, Pick n Pay are unable to accept liability for any
            incorrect information. We recommend that you do not rely solely on
            this information, but always check product labels and contact our
            dietitian (healthhotline@pnp.co.za) if you have any queries.”
          </p>
        </Box>
      </Box>

      <Footer />
    </>
  );
};

export default Disclaimer;
