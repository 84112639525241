import React from 'react'
import HeaderExplore from "./headerExplore";
import BottomExplore from "./bottomExplore";
import logo from '../../assets/logo/FoodAllergySupportApp.png';
import PRA from "../../assets/images/ProductsRecentlyAdded.png";
import HCP from '../../assets/images/findHealthCare.png';
import FP from "../../assets/images/foodProduct.png";
import SL from "../../assets/images/shoppingList.png";
import QS from "../../assets/images/quickSearch.png";
import UP from "../../assets/images/userProfile.png";
import Explore from "./explore";
import Navbar from "./navbar";
import Footer from "./footer";
import { Box } from '@mui/material';
import WhereToStart from '../../assets/images/WhereToStart.png';
import './explore.scss'
interface Link {
  url: string;
  title: string;
}


const ExplorePage = () => {
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/explore", title: "Explore Allegrow and Where To Start" },
    // { url: "/Services", title: "Services" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
    { url: "/privacy", title: "Privacy" },
  ];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar navbarLinks={lnks} />
      <Box
        sx={{
          pt: "0px",
          height: "calc(100vh - 150px)",
          flex: 1,
        }}
        className="exploreContainer"
      >
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 5, }}>
          <img className='explore-image' src={WhereToStart} style={{ maxWidth: '60%' }} />
        </Box>
        <Box
          className="top"
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            pt: 5,
            justifyContent: "center",
          }}
        >

          <Box
            className="top-right"
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="homeImage" src={logo} width={'400px'} height={'300px'} alt="Image" />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Explore
            imgSrc={UP}
            subtitle={
              "Create & update<span class='margarosa'> personalised </span> food allergy &- intolerance profiles for each family member"
            }
          />
          <Explore
            imgSrc={FP}
            subtitle={
              "Search for various <span class='margarosa'> suitable food products</span> according to user profile members needs"
            }
          />
          <Explore
            imgSrc={QS}
            subtitle={
              "Perform a <span class='margarosa'> quick search </span> to identify suitable food products <span class='margarosa'> free from </span> specific ingredients"
            }
          />
          <Explore
            imgSrc={SL}
            subtitle={
              "Create multiple shopping lists for your <span class='margarosa'> convenience. </span>Share with loved ones."
            }
          />
          <Explore
            imgSrc={HCP}
            subtitle={
              "Find a <span class='margarosa'> healthcare practitioner </span> in your area to assist with the optimal management of your food allergy / -intolerance"
            }
          />
          <BottomExplore website="https://allegrow.co.za/" />
        </Box>
      </Box>
      <Footer />
    </>
    //   <div>
    //        <Navbar navbarLinks={lnks} />
    //     <HeaderExplore/>

    //     <Footer />
    //   </div>
  );
}

export default ExplorePage
