import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  InputLabel,
  Chip,
  Checkbox,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './ingredient.scss';
import { AllergyAPI, IngredientAPI } from '../../api/services';

interface TextModalProps {
  open: boolean;
  onClose: () => void;
}

const ExportIngredient: React.FC<TextModalProps> = ({ open, onClose }) => {
  const [name, setName] = useState<string>('');
  const [selectedValues, setSelectedValues] = useState<number[]>([]);  // Changed to number[]
  const [options, setOptions] = useState<{ id: number; name: string }[]>([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [contains, setContains] = useState('');

  const handleSelectChange = (event: SelectChangeEvent<number[]>) => {
    setSelectedValues(event.target.value as number[]);  // Update to handle number[] instead of string[]
  };

  useEffect(() => {
    AllergyAPI.getAllAllergies().then((response) => {
      setOptions(response.data);
    });
  }, []);

  const handleExport = async () => {
    // Set export loading state to true
    setIsExportLoading(true);
  
    // Prepare the payload with allergy IDs and contains keywords
    const payload = {
      allergy_ids: selectedValues, // selected allergy IDs (could be empty)
      contains: contains.trim(),   // contains keywords (could be empty)
    };
  
    try {
      // Call the exportIngredients function from your API service
      const response = await IngredientAPI.exportIngredients(payload);
  
      // Create a download link for the file and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ingredients_allergies.xlsx'); // Default filename for the downloaded file
      document.body.appendChild(link);
      link.click();
  
      // Show success toast after the export completes
      toast.success('Export successful!', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
      });
      onClose();
    } catch (error) {
      // Handle errors and show failure message
      console.error('Error exporting ingredients:', error);
      toast.error('Export failed. Please try again later.', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
      });
    } finally {
      // Set loading state to false after the export process is completed
      setIsExportLoading(false);
    }
  };
  

  return (
    <Dialog open={open} onClose={onClose} className="text-modal-dialog" style={{ borderRadius: 20 }}>
      <DialogTitle style={{ fontSize: 25, fontWeight: 500 }}>Export Ingredients</DialogTitle>
      <DialogContent>
        <InputLabel style={{ marginTop: 10 }}>Select Allergies</InputLabel>
        <Select
          multiple
          value={selectedValues}
          onChange={handleSelectChange}
          displayEmpty={true}
          inputProps={{ 'aria-label': 'Select options' }}
          className="drop-down"
          renderValue={(selected) => (
            <div>
              {selected.map((value) => {
                // Find the allergy name corresponding to the selected ID
                const allergy = options.find((option) => option.id === value);
                return allergy ? <Chip key={value} label={allergy.name} /> : null;
              })}
            </div>
          )}
>           
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id}> 
              <Checkbox checked={selectedValues.indexOf(option.id) > -1} />
              {option.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="contains"
          name="contains"
          InputProps={{
            disableUnderline: true,
          }}
          placeholder="Contains (comma separated)"
          className="addText"
          onChange={(e) => setContains(e.currentTarget.value)} // Update the state
          value={contains} // Bind to the state
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {isExportLoading ? (
          <Button>
            <CircularProgress size={20} style={{ color: '#70a132' }} />
          </Button>
        ) : (
          <Button onClick={handleExport}>Export</Button>
        )}
      </DialogActions>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Dialog>
  );
};

export default ExportIngredient;
